<template>
	<v-container fluid class="pa-0">
		<v-toolbar elevation="1" :style="stickyTop">
			<v-toolbar-title>Coaches</v-toolbar-title>
			<v-btn icon @click="fetchCoaches()"
				><v-icon>mdi-refresh</v-icon></v-btn
			>
			<v-spacer />
			<!-- ADD COACH -->
			<v-btn fab icon @click="openDialogAdd">
				<v-icon> mdi-plus-circle-outline </v-icon>
			</v-btn>
			<v-dialog width="800" max-width="100vw" v-model="showDialogAdd">
				<AddCoach
					:key="dialogKey"
					v-on:closeDialogAdd="showDialogAdd = false"
					v-on:refreshList="fetchCoaches"
				/>
			</v-dialog>
			<v-spacer />
		</v-toolbar>
		<v-container>
			<v-data-table
				:items="coaches"
				:headers="headers"
				:disable-sort="this.$vuetify.breakpoint.mobile"
				:loading="loading"
				item-key="uid"
				disable-pagination
				hide-default-footer
				no-data-text=""
				no-results-text=""
			>
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn
						v-if="!item.userId"
						icon
						plain
						@click="createAccount(item)"
						>create account</v-btn
					>
					<v-btn
						v-if="item.userId && !item.emailSent"
						icon
						plain
						@click="sendEmail(item)"
						>uitnodigen
					</v-btn>
				</template>
			</v-data-table>
		</v-container>
	</v-container>
</template>

<script>
	import AddCoach from "@/components/AddCoach";
	import { db, functions } from "@/services/firebase";
	import { collection, getDocs } from "firebase/firestore";
	import { httpsCallable } from "firebase/functions";
	import { mapGetters } from "vuex";
	export default {
		name: "Coaches",
		components: { AddCoach },
		data() {
			return {
				headers: [
					{ text: "Naam", value: "name.lastName" },
					{ text: "Voornaam", value: "name.firstName" },
					{ text: "Email", value: "email" },
					{ text: "Acties", value: "actions" },
				],
				loading: false,
				coaches: [],
				tableHeight: null,
				dialogKey: 1,
				searchText: "",
				showDialogAdd: false,
			};
		},
		computed: {
			...mapGetters({}),
			stickyTop() {
				return {
					position: "sticky",
					top: this.$vuetify.application.top + "px",
					zIndex: 1,
				};
			},
		},
		methods: {
			async fetchCoaches() {
				const cRef = collection(db, "coaches");
				const res = await getDocs(cRef);
				this.coaches = res.docs.map((doc) => {
					return { ...doc.data(), id: doc.id };
				});
			},
			openDialogAdd() {
				this.dialogKey += 1;
				this.showDialogAdd = true;
			},
			async createAccount(_coach) {
				this.loading = true;
				try {
					const params = {
						email: _coach.email,
						displayName:
							_coach.name.firstName + " " + _coach.name.lastName,
						role: "coach",
						id: _coach.id,
					};
					const call = httpsCallable(functions, "createUser");
					const result = await call(params);
					_coach.userId = result.data.userId;
				} catch (e) {
					console.error(e);
				}
				this.loading = false;
			},
			async sendEmail(_coach) {
				this.loading = true;
				try {
					const params = {
						to_uid: _coach.userId,
						role: "coach",
						id: _coach.id,
					};
					const call = httpsCallable(functions, "sendEmail");
					await call(params);
					_coach.emailSent = true;
				} catch (e) {
					console.error(e);
				}
				this.loading = false;
			},
		},
		mounted() {
			this.fetchCoaches();
		},
	};
</script>

