<template>
	<v-card>
		<v-card-title>Coach toevoegen</v-card-title>
		<v-card-text>
			<v-text-field
				v-model="name.firstName"
				label="Voornaam"
			></v-text-field>
			<v-text-field
				v-model="name.lastName"
				label="Achternaam"
			></v-text-field>
			<v-text-field v-model="email" label="Email"></v-text-field>
		</v-card-text>
		<v-card-actions>
			<v-btn plain :loading="loading" @click.stop="addCoach"
				>Bevestigen</v-btn
			>
			<v-spacer />
			<v-btn plain @click="$emit('closeDialogAdd')">Annuleren</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
	import { db } from "@/services/firebase";
	import { collection, addDoc } from "firebase/firestore";
	export default {
		name: "addCoach",
		props: {},
		data() {
			return {
				loading: false,
				name: {
					firstName: "",
					lastName: "",
				},
				email: "",
			};
		},
		computed: {},
		methods: {
			async addCoach() {
				this.loading = true;
				await addDoc(collection(db, "coaches"), {
					name: this.name,
					email: this.email,
				});
				this.loading = false;
				this.$emit("refreshList");
				this.$emit("closeDialogAdd");
			},
		},
	};
</script>
